import { useTranslation } from 'react-i18next';

import { EmptyState, Text, TextLink } from '@agentero/components';
import { IconDoNotDisturbOn } from '@agentero/icons';

export const PermissionError = () => {
	const { t } = useTranslation('error');

	return (
		<EmptyState>
			<EmptyState.Icon>
				<IconDoNotDisturbOn />
			</EmptyState.Icon>
			<EmptyState.Title>{t('permission.title')}</EmptyState.Title>
			<EmptyState.Body>
				<Text mb={8}>{t('permission.body.first')}</Text>
				<Text mb={8}>
					{t('permission.body.second')}{' '}
					<TextLink href={`mailto:${t('permission.body.email')}`}>
						<b>{t('permission.body.email')}</b>
					</TextLink>
				</Text>
			</EmptyState.Body>
		</EmptyState>
	);
};
