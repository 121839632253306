import { Suspense } from 'react';

import { Error404 } from 'modules/Error404';
import { RequestErrorBoundary } from 'packages/components/RequestErrorBoundary';
import { Page } from 'packages/layout';

// TODO: Improve handling for authenticated and unauthenticated states.
// Currently:
// - Authenticated users are shown a 404 page.
// - Unauthenticated users are redirected to the login page.
// This behavior should be reviewed and adjusted for a better user experience.
const Error404Page = () => (
	<RequestErrorBoundary customError={() => <div />}>
		<Suspense>
			<Page>
				<Error404 />
			</Page>
		</Suspense>
	</RequestErrorBoundary>
);

export default Error404Page;
