import React from 'react';

import { RequestError, RequestStatusError } from '@agentero/service-errors';

import { ErrorProps } from '../RequestErrorBoundary';
import { InternalServerError } from '../error/InternalServerError';
import { PermissionError } from '../error/PermissionError';
import { RenderError } from '../error/RenderError';
import { TimeoutError } from '../error/TimeoutError';

export const DefaultRequestError: React.FC<ErrorProps> = ({ error, onReload }) => {
	const Error = getError(error);
	return <Error onReload={onReload} />;
};

const codeErrors: { [key: number]: React.ComponentType<{ onReload?: () => void }> } = {
	[RequestStatusError.RpcUnknown]: InternalServerError,
	[RequestStatusError.RpcInternal]: InternalServerError,
	[RequestStatusError.RpcUnavailable]: InternalServerError,
	[RequestStatusError.RpcDataLoss]: InternalServerError,
	[RequestStatusError.RpcPermissionDenied]: PermissionError,
	[RequestStatusError.RpcDeadlineExceeded]: TimeoutError,
	[RequestStatusError.InvalidDataReceived]: InternalServerError
	//TODO: Add mapping for fetch code errors
};

const getError = (error: RequestError | Error) => {
	if (error instanceof RequestError) {
		const ErrorComponent = codeErrors[error.statusError];
		return ErrorComponent ? ErrorComponent : InternalServerError;
	}

	return RenderError;
};
