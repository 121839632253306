import { Component, ComponentType, ErrorInfo, PropsWithChildren } from 'react';

import * as Sentry from '@sentry/nextjs';
import { ConnectError } from '@connectrpc/connect';
import { QueryErrorResetBoundary, useQueryErrorResetBoundary } from '@tanstack/react-query';

import { LogLevel, RequestError } from '@agentero/service-errors';
import { isGRPCError } from '@agentero/service-errors/src/RequestStatusError';

import { addLog } from 'packages/utilities/logger';

import { DefaultRequestError } from './requestErrorBoundary/DefaultRequestError';

type RequestErrorBoundaryProps = {
	customError?: ComponentType<ErrorProps>;
};

export type ErrorProps = {
	error: RequestError | Error;
	onReload: () => void;
};

type RequestErrorBoundaryState = {
	error?: RequestError | Error;
};

export class RequestErrorBoundary extends Component<
	PropsWithChildren<RequestErrorBoundaryProps>,
	RequestErrorBoundaryState
> {
	constructor(props) {
		super(props);

		this.state = {
			error: undefined
		};
	}

	static getDerivedStateFromError(error: RequestError | Error) {
		// Update state so the next render will show the fallback UI.
		return { error };
	}

	componentDidCatch = (error: Error | RequestError, info: ErrorInfo) => {
		if (error instanceof RequestError) {
			error.logError(addLog, info.componentStack);
		} else {
			const err = error as ConnectError;
			const logLevel = err && isGRPCError(err.code) ? LogLevel.Warn : LogLevel.Error;
			addLog({ ...error, stack: info.componentStack }, logLevel);
		}
		Sentry.captureException(this.state.error);
	};

	onReload = () => this.setState({ error: undefined });

	render = () => {
		const ErrorComponent = this.props.customError || DefaultRequestError;

		return !this.state.error ? (
			this.props.children
		) : (
			<QueryErrorResetBoundary>
				{() => {
					const { reset } = useQueryErrorResetBoundary();
					return (
						<ErrorComponent
							error={this.state.error}
							onReload={() => {
								reset();
								this.onReload();
							}}
						/>
					);
				}}
			</QueryErrorResetBoundary>
		);
	};
}
