import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import { EmptyState } from '@agentero/components';
import { IconTimer } from '@agentero/icons';
import { Button } from '@agentero/ui';

type TimeoutErrorProps = {
	onReload: () => void;
};

export const TimeoutError = ({ onReload }: TimeoutErrorProps) => {
	const { t } = useTranslation('error');

	return (
		<EmptyState>
			<EmptyState.Icon>
				<IconTimer />
			</EmptyState.Icon>
			<EmptyState.Title>{t('timeout.title')}</EmptyState.Title>
			<EmptyState.Body>
				{t('timeout.body.start')}{' '}
				<Link href={`mailto:${t('timeout.body.email')}`}>
					<b>{t('timeout.body.email')}</b>
				</Link>{' '}
				{t('timeout.body.end')}
			</EmptyState.Body>
			<EmptyState.Actions>
				<Button onClick={onReload}>{t('timeout.actions.primary')}</Button>
			</EmptyState.Actions>
		</EmptyState>
	);
};
